import { GlobalOutlined } from '@ant-design/icons'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonVariant, MyButton } from '../Button/Button'
import cls from './LangSwitcher.module.scss'

interface Props {
    variant?: ButtonVariant
}
export const LangSwitcher = memo(({ variant = 'clear' }: Props) => {
    const { t, i18n } = useTranslation()

    const toggle = () => {
        const newLanguage = i18n.language === 'ru' ? 'en' : 'ru'
        i18n.changeLanguage(newLanguage)
    }

    return (
        <MyButton variant={variant} onClick={toggle} color="normal">
            <GlobalOutlined className={cls.icon} />
            {t('Язык')}
        </MyButton>
    )
})
