import React, { ReactNode } from 'react'
import { notification } from 'antd'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

let notificationApi: any = null
let contextHolder: React.ReactNode = null

const initializeNotification = () => {
    ;[notificationApi, contextHolder] = notification.useNotification()
}

const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string
) => {
    if (notificationApi) {
        notificationApi[type]({
            message,
            description
        })
    }
}

interface Props {
    children: ReactNode
}
const NotificationProvider = (props: Props) => {
    initializeNotification()
    return (
        <>
            {contextHolder}
            {props.children}
        </>
    )
}

export { NotificationProvider, openNotificationWithIcon }
