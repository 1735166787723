import { Button, Input } from 'antd'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AuthByUsernameActions } from '../..'
import { BurgerMenuActions } from '../../../../entities/BurgerMenu'
import { getUserIsAuth } from '../../../../entities/User'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { getRouteMainPage } from '../../../../shared/lib/const/router'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { VStack } from '../../../../shared/ui/Stack'
import { Text } from '../../../../shared/ui/Text'
import { fetchUserTags } from '../../../Tags/model/service'
import { getLoginUsernameData } from '../../model/selectors/getAuthByUsernamedata'
import { loginByUsername } from '../../model/services/loginByUsername'
import cls from './AuthByUsername.module.scss'
import { openNotificationWithIcon } from '../../../../app/providers/NotificationProvider'

interface AuthByUsernameProps {
    className?: string
}

export const AuthByUsername = (props: AuthByUsernameProps) => {
    const { className } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isAuth = useSelector(getUserIsAuth)

    const { isLoading, password, email, error } =
        useSelector(getLoginUsernameData)

    const onChangeUsername = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(AuthByUsernameActions.setUsername(event.target.value))
        },
        [dispatch]
    )

    const onChangePassword = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(AuthByUsernameActions.setPassword(event.target.value))
        },
        [dispatch]
    )

    const navigateOnSuccess = useCallback(() => {
        navigate(getRouteMainPage())
        dispatch(BurgerMenuActions.set(false))
        dispatch(fetchUserTags())
        openNotificationWithIcon('success', t('Уведомление'), t('Авторизация прошла успешно'))
    }, [navigate, dispatch, t])

    const onLoginClick = useCallback(async () => {
        await dispatch(
            loginByUsername({ email, password, callback: navigateOnSuccess })
        )
    }, [dispatch, email, password, navigateOnSuccess])

    if (isAuth) {
        return <Text text="Вы авторизованы!" />
    }

    return (
        <div className={classNames(cls.AuthByUsername, {}, [className])}>
            <VStack
                max
                gap="16"
                className={classNames(cls.LoginForm, {}, [className])}
            >
                {error && (
                    <Text
                        text={t('Неверный логин или пароль')}
                        variant="error"
                    />
                )}
                <Input
                    disabled={isLoading}
                    type="text"
                    className={cls.input}
                    placeholder={t('Введите email')}
                    onChange={onChangeUsername}
                    value={email}
                    onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                            onLoginClick()
                        }
                    }}
                />
                <Input.Password
                    disabled={isLoading}
                    type="text"
                    className={cls.input}
                    placeholder={t('Введите пароль')}
                    onChange={onChangePassword}
                    value={password}
                    onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                            onLoginClick()
                        }
                    }}
                />
                {/* здесь нужно доделать свой компонент MyButton */}
                <VStack align="end" max>
                    <Button
                        className={cls.loginBtn}
                        onClick={onLoginClick}
                        disabled={isLoading}
                    >
                        {t('Войти')}
                    </Button>
                </VStack>
                {isLoading && <Loader />}
            </VStack>
        </div>
    )
}
