import React, { ButtonHTMLAttributes, ForwardedRef, ReactNode } from 'react'
import { classNames, Mods } from '../../lib/classNames/classNames'
import cls from './Button.module.scss'

export type ButtonVariant = 'clear' | 'outline' | 'filled' | 'link'
export type ButtonColor = 'normal' | 'success' | 'error'
export type ButtonSize = 's' | 'm' | 'l' | 'xl'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    variant?: ButtonVariant
    square?: boolean
    size?: ButtonSize
    disabled?: boolean
    children?: ReactNode
    fullWidth?: boolean
    fullHeight?: boolean
    color?: ButtonColor
    addonLeft?: ReactNode
    addonRight?: ReactNode
}

export const MyButton = React.forwardRef(
    (
        {
            className,
            children,
            variant = 'outline',
            square,
            disabled,
            fullWidth,
            fullHeight,
            size = 'm',
            addonLeft,
            addonRight,
            color = 'normal',
            ...otherProps
        }: ButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        const mods: Mods = {
            [cls.square]: square,
            [cls.disabled]: disabled,
            [cls.fullWidth]: fullWidth,
            [cls.fullHeight]: fullHeight,
            [cls.withAddon]: Boolean(addonLeft) || Boolean(addonRight)
        }

        return (
            <button
                type="button"
                className={classNames(cls.Button, mods, [
                    className,
                    cls[variant],
                    cls[size],
                    cls[color]
                ])}
                disabled={disabled}
                {...otherProps}
                ref={ref}
            >
                {addonLeft && <div className={cls.addonLeft}>{addonLeft}</div>}
                {children}
                {addonRight && (
                    <div className={cls.addonRight}>{addonRight}</div>
                )}
            </button>
        )
    }
)
