import { Input, Tag } from 'antd'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { openNotificationWithIcon } from '../../../../app/providers/NotificationProvider'
import useApiRequest from '../../../../shared/lib/hooks/useApiRequest'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { HStack, VStack } from '../../../../shared/ui/Stack'
import { Text } from '../../../../shared/ui/Text'
import { getTagsdata } from '../../model/selectors/getTagsdata'
import { addUserTagsService, fetchUserTags } from '../../model/service'
import { TagsActions } from '../../model/slices/TagsSlice'

interface TagsProps {
    className?: string
}

const Tags = memo((props: TagsProps) => {
    const [searchTagInput, setSearchTagInput] = useState('')
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const { isLoading, error, searchTags } = useSelector(getTagsdata)

    const fetchTags = () => {
        dispatch(fetchUserTags())
    }

    const {
        error: addError,
        isLoading: addLoading,
        fetchData: addTag
    } = useApiRequest(addUserTagsService, t('Успешно сохранено!'), fetchTags)

    const handleSaveTags = () => {
        addTag({ searchTags: searchTags })
    }

    const handleAddTag = () => {
        const newTag = searchTagInput.trim()
        if (newTag.length > 13) {
            return openNotificationWithIcon(
                'error',
                t('Уведомление!'),
                t('Максимальная длина 13 символов!')
            )
        }
        if (searchTags.includes(newTag)) {
            return openNotificationWithIcon(
                'error',
                t('Уведомление!'),
                t('Тег уже есть в списке')
            )
        }

        if (newTag && !searchTags.includes(newTag)) {
            dispatch(TagsActions.setSearchTags([...searchTags, newTag]))
            setSearchTagInput('')
        }
    }

    const handleRemoveTag = (removedTag: string) => {
        const index = searchTags.indexOf(removedTag)

        if (index !== -1) {
            const updatedTags = [...searchTags]
            updatedTags.splice(index, 1)
            console.log('Updated Tags:', updatedTags)
            dispatch(TagsActions.setSearchTags(updatedTags))
        }
    }

    if (error) {
        return <Text text={error} variant="error" />
    }

    return (
        <VStack gap="4" align="end" justify="end" key={searchTags.length}>
            <HStack wrap="wrap" max align="start" justify="start" gap="4">
                {isLoading ? (
                    <Loader />
                ) : (
                    searchTags.map((tag, index) => (
                        <Tag
                            color="green"
                            closable
                            onClose={() => handleRemoveTag(tag)}
                            key={index}
                        >
                            {tag}
                        </Tag>
                    ))
                )}
            </HStack>
            <Input
                placeholder={t('Введите тег поиска')}
                value={searchTagInput}
                onChange={(e) => setSearchTagInput(e.target.value)}
                onPressEnter={handleAddTag}
                style={{ marginBottom: 10 }}
            />

            <HStack max justify="between" align="start">
                <div style={{ marginBottom: 10 }}>
                    {!searchTags.length && (
                        <Tag color="green" className="vibrate">
                            <h3> {t('У вас нет тегов, добавьте первый!')}</h3>
                        </Tag>
                    )}
                </div>
                <HStack gap="4">
                    <MyButton
                        onClick={handleAddTag}
                        variant="outline"
                        disabled={searchTagInput.length < 3}
                        size="s"
                    >
                        {t('Добавить')}
                    </MyButton>
                    {!!searchTags.length && (
                        <MyButton
                            disabled={addLoading}
                            onClick={handleSaveTags}
                            variant="filled"
                            size="s"
                        >
                            {addLoading ? t('Сохранение...') : t('Сохранить')}{' '}
                        </MyButton>
                    )}
                </HStack>
            </HStack>
            {addError && <Text text={t('Ошибка')} variant="error" />}
        </VStack>
    )
})

export default Tags
