import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AuthButton from '../../../../features/AuthByUsername/ui/AuthButton/AuthButton'
import { UserProfile } from '../../../../features/UserProfile'
import { classNames, Mods } from '../../../../shared/lib/classNames/classNames'
import { getRouteUserRegisterPage } from '../../../../shared/lib/const/router'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { LangSwitcher } from '../../../../shared/ui/LangSwitcher/LangSwitcher'
import { HStack } from '../../../../shared/ui/Stack'
import { ThemeSwitcher } from '../../../../shared/ui/ThemeSwitcher'
import { getUserIsAuth } from '../../../User'
import { getBurgerMenudata } from '../../model/selectors/getBurgerMenudata'
import { BurgerMenuActions } from '../../model/slices/BurgerMenuSlice'
import cls from './DropDown.module.scss'

export const DropDown = () => {
    const { isOpen } = useSelector(getBurgerMenudata)
    const { t } = useTranslation()
    const isAuth = useSelector(getUserIsAuth)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const close = () => {
        dispatch(BurgerMenuActions.set(false))
    }

    const handleClickRegister = () => {
        close()
        return navigate(getRouteUserRegisterPage())
    }

    const mods: Mods = {
        [cls.active]: isOpen
    }
    return (
        <div className={classNames(cls.mobile_nav, mods, [])}>
            {isAuth && <UserProfile />}
            {!isAuth && (
                <HStack max align="end" gap="24" wrap="wrap">
                    <AuthButton color="success" />
                    <LangSwitcher variant="outline" />
                    <ThemeSwitcher />
                    <MyButton onClick={handleClickRegister} variant="filled">
                        {t('Регистрация')}
                    </MyButton>
                </HStack>
            )}
        </div>
    )
}
