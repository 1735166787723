import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { TenderKpis } from '../types/TenderStatisticsSchema';



export const fetchTenders_kpis = createAsyncThunk<TenderKpis, void, ThunkConfig<string>>(
    'kpis/fetchTenders_kpis',
    async (_, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<TenderKpis>('/tenders-api/get_kpis');
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных!');
        }
    },
)