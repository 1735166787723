import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSchema } from '../types/UserSchema';
import { fetchRefreshToken } from '../services';
import { USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage';

const initialState: UserSchema = {
    isAuth: false,
    _inited: false
};

export const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload
        },
        initAuth: (state) => {
            state._inited = true
        },
        logOut: (state) => {
            state.isAuth = false
            localStorage.removeItem(USER_TOKEN_KEY)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRefreshToken.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchRefreshToken.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchRefreshToken.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: UserActions } = UserSlice;
export const { reducer: UserReducer } = UserSlice;