import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BurgerMenuSchema } from '../types/BurgerMenuSchema';

const initialState: BurgerMenuSchema = {
    isOpen: false
};

export const BurgerMenuSlice = createSlice({
    name: 'BurgerMenu',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
    },

});

export const { actions: BurgerMenuActions } = BurgerMenuSlice;
export const { reducer: BurgerMenuReducer } = BurgerMenuSlice;