import { useEffect } from 'react'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { fetchTenders } from '../../model/services'
import { TendersCardViewNew } from '../TendersCardView/TendersCardViewNew'
import cls from './TendersNew.module.scss'

export const TendersNew = () => {
    // const [view, setView] = useState<true | false>(true)
    const dispatch = useAppDispatch()
    // const parent = useRef(null)
    // const handleChangeView = () => {
    //     setView(!view)
    // }
    // useEffect(() => {
    //     parent.current && autoAnimate(parent.current)
    // }, [parent])

    useEffect(() => {
        dispatch(fetchTenders({ pageNumber: 1, perPage: 10 }))
    }, [dispatch])

    return (
        <div className={classNames(cls.TendersNew, {}, [])}>
            <>
                {/* <HStack justify="end">
                    <MyButton
                        onClick={handleChangeView}
                        variant="clear"
                        className={cls.btn}
                    >
                        {view ? (
                            <UnorderedListOutlined />
                        ) : (
                            <BorderlessTableOutlined />
                        )}
                    </MyButton>
                </HStack> */}
                {/* <div ref={parent}>{view ? <TendersCardViewNew /> : <></>}</div> */}
                <TendersCardViewNew />
            </>
        </div>
    )
}
