interface RequireAuthProps {
    children: JSX.Element
    roles?: any[]
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
    const isAuth = true

    if (!isAuth) {
        return <div>not found</div>
    }

    return children
}
