import { useTranslation } from 'react-i18next'
import styles from './ErrorPage.module.scss'

interface ErrorPageProps {
    className?: string
}

export const ErrorPage = ({ className }: ErrorPageProps) => {
    const { t } = useTranslation()
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.errorCode}>404</h1>
            <p className={styles.errorMessage}>
                {t('Произошла непредвиденная ошибка!')}
            </p>
            <button className={styles.refreshButton} onClick={reloadPage}>
                {t('Обновить страницу')}
            </button>
        </div>
    )
}
