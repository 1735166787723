import axios from "axios";
import { isTokenAvailable } from "../lib/helpers/tokenAvailability";
import { USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY } from "../lib/const/localstorage";




const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false
})

$api.interceptors.request.use((config: any) => {

    if (isTokenAvailable()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(USER_TOKEN_KEY)}`
    }
    return config;
})


const clearLocalStorage = () => {
    localStorage.removeItem(USER_TOKEN_KEY)
    localStorage.removeItem(USER_REFRESH_TOKEN_KEY)
    window.location.replace('/')
    window.location.reload()

}
const refreshAccessToken = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
            refresh: localStorage.getItem(USER_REFRESH_TOKEN_KEY),
        });
        localStorage.setItem(USER_TOKEN_KEY, response.data.access);
        return response.data.accessToken;
    } catch (error) {
        clearLocalStorage()
    }
};


$api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Помечаем, что мы уже пытались повторить запрос
            const newAccessToken = await refreshAccessToken(); // Получаем новый access токен
            console.log(newAccessToken)
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`; // Обновляем токен в заголовках
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`; // Обновляем токен в текущем запросе
            return $api(originalRequest); // Повторяем запрос с новым токеном
        }
        return Promise.reject(error); // Передаем ошибку дальше, если условия не совпадают
    }
);


export default $api