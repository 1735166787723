import { FloatButton } from 'antd'
import { InfiniteScroll } from 'antd-mobile'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import TenderCardSkeleton from '../../../../shared/ui/TenderCardSkeleton/TenderCardSkeleton'
import { Text } from '../../../../shared/ui/Text'
import {
    getFoundTendersdata,
    searchTendersInfinite
} from '../../../FoundTenders'
import { getIsSearchingStatus } from '../../../IsSearching'
import { getTendersdata } from '../../model/selectors/getTendersdata'
import { fetchTendersInfinite } from '../../model/services'
import TenderCard from '../TenderCard/TenderCard'
import cls from './TendersCardView.module.scss'

interface Props {}
export const TendersCardViewNew = (props: Props) => {
    const dispatch = useAppDispatch()
    const isSearching = useSelector(getIsSearchingStatus)
    const { t } = useTranslation()

    const { tenders, overall_tenders_count, isLoading } =
        useSelector(getTendersdata)
    const {
        foundtenders,
        overall_tenders_count_found,
        isLoading: searching
    } = useSelector(getFoundTendersdata)

    const hasMore = useMemo((): boolean => {
        if (isSearching) {
            return foundtenders.length < overall_tenders_count_found
        } else {
            return tenders.length < overall_tenders_count
        }
    }, [
        overall_tenders_count,
        overall_tenders_count_found,
        tenders,
        isSearching,
        foundtenders
    ])

    const [page, setPage] = useState(2)

    async function loadMoreData() {
        if (searching) {
            return
        }

        if (isLoading) {
            return
        }

        const params = {
            pageNumber: page,
            perPage: 5
        }

        if (isSearching) {
            dispatch(searchTendersInfinite(params))
        } else {
            dispatch(fetchTendersInfinite(params))
        }
        setPage(page + 1)
    }

    return (
        <div className={classNames(cls.list, {}, [])}>
            {/* { isSearching && <div>Найдено {foundtenders}</div>} */}
            {isSearching
                ? foundtenders?.map((tender, i) => (
                      <TenderCard key={i} tenderItem={tender} index={i + 1} />
                  ))
                : tenders?.map((tender, i) => (
                      <TenderCard key={i} tenderItem={tender} index={i + 1} />
                  ))}

            <InfiniteScroll
                children={
                    hasMore ? (
                        <TenderCardSkeleton />
                    ) : searching || isLoading ? (
                        <Loader />
                    ) : (
                        <Text text={t('Нет тендеров для отображенвя')} />
                    )
                }
                loadMore={loadMoreData}
                hasMore={hasMore}
            />
            <FloatButton.BackTop />
        </div>
    )
}
