import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenderStatisticsSchema } from '../types/TenderStatisticsSchema';
import { fetchTenders_kpis } from '../services';

const initialState: TenderStatisticsSchema = {

};

export const TenderStatisticsSlice = createSlice({
    name: 'TenderStatistics',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenders_kpis.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchTenders_kpis.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload
            })
            .addCase(fetchTenders_kpis.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: TenderStatisticsActions } = TenderStatisticsSlice;
export const { reducer: TenderStatisticsReducer } = TenderStatisticsSlice;