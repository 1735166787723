import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TendersSchema } from '../types/TendersSchema'
import { fetchTenders, fetchTendersInfinite } from '../services'

const initialState: TendersSchema = {
    overall_tenders_count: 0,
    tenders: []
}

export const TendersSlice = createSlice({
    name: 'Tenders',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => { }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenders.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchTenders.fulfilled, (state, action) => {
                state.isLoading = false
                state.tenders = action.payload.tenders
                state.overall_tenders_count =
                    action.payload.overall_tenders_count
            })
            .addCase(fetchTenders.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(fetchTendersInfinite.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchTendersInfinite.fulfilled, (state, action) => {
                state.isLoading = false
                let newData = state.tenders ? [...state.tenders] : []
                state.tenders = [...newData, ...action.payload.tenders]
                state.overall_tenders_count =
                    action.payload.overall_tenders_count
            })
            .addCase(fetchTendersInfinite.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: TendersActions } = TendersSlice
export const { reducer: TendersReducer } = TendersSlice
