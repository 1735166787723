import { Col, Grid, Row, Spin } from 'antd'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTenderStatisticsdata } from '../..'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { MainGridComponent } from '../../../../shared/ui/MainGridComponent/MainGridComponent'
import { Text } from '../../../../shared/ui/Text'
import cls from './TenderStatistics.module.scss'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { fetchTenders_kpis } from '../../model/services'

interface TenderStatisticsProps {
    className?: string
}

const commonProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6
}

const { useBreakpoint } = Grid

const TenderStatistics = memo((props: TenderStatisticsProps) => {
    const { className } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { data, error, isLoading } = useSelector(getTenderStatisticsdata)

    const s = useBreakpoint()
    const hideStatistics = (s.xs && !s.sm) || (!s.xs && s.sm && !s.lg)

    useEffect(() => {
        dispatch(fetchTenders_kpis())
        //eslint-disable-next-line
    }, [])

    return (
        <div className={classNames(cls.TenderStatistics, {}, [className])}>
            <MainGridComponent>
                {isLoading ? (
                    <Spin />
                ) : error ? (
                    <Text text={error} variant="error" />
                ) : (
                    <Row justify={'start'}>
                        {' '}
                        <Col {...commonProps}>
                            <div className={cls.stat_card}>
                                <div className={cls.title}>
                                    {data?.tenders_count?.toLocaleString(
                                        'ru-RU'
                                    )}
                                </div>
                                <div className={cls.text}>
                                    {t('Актуальных тендеров')} <br />
                                    {t('в базе данных')}
                                </div>
                            </div>
                        </Col>
                        <Col {...commonProps}>
                            <div className={cls.stat_card}>
                                <div
                                    className={classNames(cls.title, {}, [
                                        cls.bold
                                    ])}
                                >
                                    {data?.planned_amount_sum_in_millions?.toLocaleString(
                                        'ru-RU'
                                    )}
                                </div>
                                <div className={classNames(cls.text, {}, [])}>
                                    {t('Сумма тендеров')} <br /> (
                                    {t('миллионов сом')})
                                </div>
                            </div>
                        </Col>
                        {!hideStatistics && (
                            <Col {...commonProps}>
                                <div className={cls.stat_card}>
                                    <div className={cls.title}>
                                        {data?.scraped_websites}
                                    </div>
                                    <div className={cls.text}>
                                        {t('Количество обработанных')} <br />
                                        {t('сайтов')}
                                    </div>
                                </div>
                            </Col>
                        )}
                        {!hideStatistics && (
                            <Col {...commonProps}>
                                <div className={cls.stat_card}>
                                    <div className={cls.title_small}>
                                        {data?.last_update_datetime}
                                    </div>
                                    <div className={cls.text}>
                                        {t('Дата последнего')} <br />
                                        {t('обновления')}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                )}
            </MainGridComponent>
        </div>
    )
})

export default TenderStatistics
