import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TagsSchema } from '../types/TagsSchema'
import { fetchUserTags } from '../service'

const initialState: TagsSchema = {
    searchTags: []
}

export const TagsSlice = createSlice({
    name: 'Tags',
    initialState,
    reducers: {
        setSearchTags: (state, action: PayloadAction<string[]>) => {
            state.searchTags = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserTags.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchUserTags.fulfilled, (state, action) => {
                state.isLoading = false
                state.searchTags = action.payload.searchTags
            })
            .addCase(fetchUserTags.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: TagsActions } = TagsSlice
export const { reducer: TagsReducer } = TagsSlice
