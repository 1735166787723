import { Avatar } from 'antd'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { getRouteUserProfilePage } from '../../../../shared/lib/const/router'
import { AppLink } from '../../../../shared/ui/AppLink'
import cls from './UserProfileButton.module.scss'

export const UserProfileButton = () => {
    return (
        <AppLink
            to={getRouteUserProfilePage()}
            className={classNames(cls.UserProfileButton, {}, [])}
        >
            <Avatar size={'default'}>USER</Avatar>
        </AppLink>
    )
}
