import { configureStore } from '@reduxjs/toolkit'
import { counterReducer } from '../../../../entities/Counter/'
import { FoundTendersReducer } from '../../../../entities/FoundTenders'
import { TendersReducer } from '../../../../entities/Tenders/model/slices/TendersSlice'
import { TenderStatisticsReducer } from '../../../../entities/TenderStatistics'
import { UserReducer } from '../../../../entities/User'
import { AuthByUsernameReducer } from '../../../../features/AuthByUsername'
import { TenderFilterReducer } from '../../../../features/TenderFilter'
import { UserRegisterReducer } from '../../../../features/UserRegister'
import $api from '../../../../shared/api/api'
import { StateSchema } from './StateSchema'
import { BurgerMenuReducer } from '../../../../entities/BurgerMenu'
import { IsSearchingReducer } from '../../../../entities/IsSearching'
import { TagsReducer } from '../../../../features/Tags'

export function createReduxStore(initialState?: StateSchema) {
    return configureStore({
        reducer: {
            counter: counterReducer,
            auth_by_username: AuthByUsernameReducer,
            user: UserReducer,
            tenders: TendersReducer,
            tender_kpis: TenderStatisticsReducer,
            tender_filers: TenderFilterReducer,
            found_tenders: FoundTendersReducer,
            user_register: UserRegisterReducer,
            burger_menu: BurgerMenuReducer,
            is_searching: IsSearchingReducer,
            userTags: TagsReducer
        },
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: {
                        api: $api
                    }
                }
            })
    })
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
