import { Skeleton } from 'antd'
import { memo } from 'react'
import { classNames } from '../../lib/classNames/classNames'
import { VStack } from '../Stack'
import cls from './TenderCardSkeleton.module.scss'

const TenderCardSkeleton = memo(() => {
    return (
        <div className={classNames(cls.TenderCard, {}, [])}>
            <div className={cls.tender_number}>
                <Skeleton title={{ width: '100%' }} active />
            </div>
            <div className={cls.tender_title}></div>
            <div className={cls.tender_description}></div>
            <div className={cls.sections}>
                <section className={cls.section}>
                    <div className={cls.tender_info}>
                        <div
                            className={`${cls.info_item} ${cls.info_item_small}`}
                        >
                            <div></div>
                        </div>
                        <div className={cls.info_item}>
                            <div></div>
                        </div>
                    </div>
                    <div className={cls.tender_info}>
                        <div
                            className={`${cls.info_item} ${cls.info_item_small}`}
                        >
                            <div>
                                <VStack max gap="8">
                                    <Skeleton.Input active size="small" />
                                    <Skeleton.Input active size="small" />
                                </VStack>
                            </div>
                        </div>
                        <div className={cls.info_item}></div>
                    </div>
                </section>
            </div>
        </div>
    )
})

export default TenderCardSkeleton
