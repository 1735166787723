import { Modal } from 'antd'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthByUsername } from '../..'
import {
    ButtonColor,
    ButtonVariant,
    MyButton
} from '../../../../shared/ui/Button'
import { Loader } from '../../../../shared/ui/Loader/Loader'

interface Props {
    variant?: ButtonVariant
    color?: ButtonColor
}
const AuthButton = (props: Props) => {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <MyButton
                variant={props.variant}
                onClick={showModal}
                color={props.color}
            >
                {t('Войти')}
            </MyButton>

            <Modal
                destroyOnClose
                footer={false}
                open={isModalOpen}
                onCancel={handleCancel}
                title={t('Форма авторизации')}
            >
                <Suspense fallback={<Loader />}>
                    <AuthByUsername />
                </Suspense>
            </Modal>
        </>
    )
}

export default AuthButton
