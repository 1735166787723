import { LinkProps, NavLink } from 'react-router-dom'
import { memo, ReactNode } from 'react'
import cls from './AppLink.module.scss'
import { classNames } from '../../lib/classNames/classNames'
import { useAppDispatch } from '../../lib/hooks/useAppDispatch/useAppDispatch'
import { BurgerMenuActions } from '../../../entities/BurgerMenu'

export type AppLinkVariant = 'primary' | 'red'

interface AppLinkProps extends LinkProps {
    className?: string
    variant?: AppLinkVariant
    children?: ReactNode
    activeClassName?: string
}

export const AppLink = memo((props: AppLinkProps) => {
    const {
        to,
        className,
        children,
        variant = 'primary',
        activeClassName = '',
        ...otherProps
    } = props

    const dispatch = useAppDispatch()

    const closeBurgerMenu = () => {
        dispatch(BurgerMenuActions.set(false))
    }
    return (
        <NavLink
            onClick={closeBurgerMenu}
            to={to}
            className={({ isActive }) =>
                classNames(cls.AppLink, { [activeClassName]: isActive }, [
                    className,
                    cls[variant]
                ])
            }
            {...otherProps}
        >
            {children}
        </NavLink>
    )
})
