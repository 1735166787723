import { memo } from 'react'
import { Theme, useTheme } from '../../../../app/providers/ThemeProvider'
import { classNames } from '../../../lib/classNames/classNames'
import { MyButton } from '../../Button/Button'
import cls from './ThemeSwitcher.module.scss'
import { useTranslation } from 'react-i18next'

interface ThemeSwitcherProps {
    className?: string
}

export const ThemeSwitcher = memo(({ className }: ThemeSwitcherProps) => {
    const { theme, toggleTheme } = useTheme()
    const { t } = useTranslation()

    return (
        <MyButton
            className={classNames(cls.ThemeSwitcher, {}, [className])}
            onClick={toggleTheme}
        >
            {theme === Theme.DARK ? t('Тёмная') : t('Светлая')}
        </MyButton>
    )
})
