import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRegisterSchema } from '../types/UserRegisterSchema';
import { fetchUserRegister } from '../services';

const initialState: UserRegisterSchema = {

    email: '',
    password: '',
    password2: ''
};

export const UserRegisterSlice = createSlice({
    name: 'UserRegister',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        },
        setPassword2: (state, action: PayloadAction<string>) => {
            state.password2 = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRegister.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchUserRegister.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchUserRegister.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: UserRegisterActions } = UserRegisterSlice;
export const { reducer: UserRegisterReducer } = UserRegisterSlice;