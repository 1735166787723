import { SearchOutlined } from '@ant-design/icons'
import {
    Col,
    DatePicker as DatePickerBrowser,
    Grid,
    Input,
    InputNumber,
    Row
} from 'antd'
import dayjs from 'dayjs'
import { ChangeEvent, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { searchTenders } from '../../../../entities/FoundTenders/model/services'
import { IsSearchingActions } from '../../../../entities/IsSearching'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { getTenderFilterdata } from '../../model/selectors/getTenderFilterdata'
import { TenderFilterActions } from '../../model/slices/TenderFilterSlice'

import { getFoundTendersdata } from '../../../../entities/FoundTenders'
import { fetchTenders } from '../../../../entities/Tenders'
import { HStack } from '../../../../shared/ui/Stack'
import cls from './TenderFilter.module.scss'
import { openNotificationWithIcon } from '../../../../app/providers/NotificationProvider'

interface TenderFilterProps {
    className?: string
}

const commonProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 4
}

const { useBreakpoint } = Grid

const TenderFilter = memo((props: TenderFilterProps) => {
    const { className } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const s = useBreakpoint()
    const isSmall = (s.xs && !s.sm) || (!s.xs && s.sm && !s.lg)
    const {
        searchKeyword,
        deadlineEnd,
        plannedAmountEnd,
        // deadlineStart,
        organization,
        plannedAmountStart,
        // publishDateEnd,
        publishDateStart
    } = useSelector(getTenderFilterdata)
    const { isLoading } = useSelector(getFoundTendersdata)
    const [searchParams] = useSearchParams()
    const sizeFromParams = searchParams.get('size')

    const onResetClick = () => {
        dispatch(IsSearchingActions.set(false))
        dispatch(TenderFilterActions.reset())
        dispatch(fetchTenders({ perPage: 10, pageNumber: 1 }))
    }

    // main on Search
    const onSearchClick = useCallback(() => {
        if (plannedAmountStart && plannedAmountEnd) {
            if (plannedAmountStart > plannedAmountEnd) {
                return openNotificationWithIcon(
                    'error',
                    t('Уведомление!'),
                    t('Сумма С не может быть больше сумма До')
                )
            }
        }
        dispatch(IsSearchingActions.set(true))

        dispatch(
            searchTenders({
                pageNumber: 1,
                perPage: sizeFromParams ? Number(sizeFromParams) : 15
            })
        )
    }, [dispatch, sizeFromParams, plannedAmountEnd, plannedAmountStart, t])
    // main on Search end

    const handleChangeSearchKeyword = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(TenderFilterActions.searchKeyword(e.target.value))
        },
        [dispatch]
    )

    const handleChangePublishStartDate = useCallback(
        (e: dayjs.Dayjs) => {
            dispatch(
                TenderFilterActions.publishDateStart(
                    dayjs(e).format('YYYY-MM-DD HH:MM:ss')
                )
            )
        },
        [dispatch]
    )

    // const handleChangePublishEndDate = useCallback(
    //     (e: dayjs.Dayjs) => {
    //         dispatch(
    //             TenderFilterActions.publishDateEnd(
    //                 dayjs(e).format('YYYY-MM-DD HH:MM:ss')
    //             )
    //         )
    //         console.log(dayjs(e).format('YYYY-MM-DD HH:MM:ss'))
    //     },
    //     [dispatch]
    // )
    const handleChangeOrganization = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(TenderFilterActions.organization(e.target.value))
        },
        [dispatch]
    )

    const handleChangeDeadlineEnd = useCallback(
        (e: dayjs.Dayjs) => {
            dispatch(
                TenderFilterActions.deadlineEnd(
                    dayjs(e).format('YYYY-MM-DD HH:MM:ss')
                )
            )
        },
        [dispatch]
    )

    // const handleChangeDeadlineStart = useCallback(
    //     (e: dayjs.Dayjs) => {
    //         dispatch(
    //             TenderFilterActions.deadlineStart(
    //                 dayjs(e).format('YYYY-MM-DD HH:MM:ss')
    //             )
    //         )
    //     },
    //     [dispatch]
    // )

    const handleChangePlannedAmountStart = useCallback(
        (e: number | null) => {
            if (e && e === 0)
                dispatch(TenderFilterActions.plannedAmountStart(e))
        },
        [dispatch]
    )
    const handleChangePlannedAmountEnd = useCallback(
        (e: number | null) => {
            if (e && e === 0) dispatch(TenderFilterActions.plannedAmountEnd(e))
        },
        [dispatch]
    )

    return (
        <div className={classNames(cls.TenderFilter, {}, [className])}>
            <Row gutter={!isSmall ? [10, 20] : [8, 10]}>
                <Col span={20}>
                    <Input
                        onChange={handleChangeSearchKeyword}
                        size="large"
                        rootClassName={cls.input}
                        placeholder={t('Поиск')}
                        prefix={<SearchOutlined width={'100px'} height={200} />}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        value={searchKeyword}
                    />
                </Col>
                <Col span={4} style={{ display: 'flex' }}>
                    <MyButton
                        color="normal"
                        variant="filled"
                        size={isSmall ? 's' : 'm'}
                        fullWidth
                        onClick={onSearchClick}
                    >
                        {isLoading ? t('Ищем...') : t('Искать')}
                    </MyButton>
                </Col>
                <Col {...commonProps}>
                    <DatePickerBrowser
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        inputReadOnly
                        size="large"
                        className={cls.datePicker}
                        onChange={handleChangePublishStartDate}
                        value={
                            publishDateStart ? dayjs(publishDateStart) : null
                        }
                        allowClear={false}
                        placeholder={t('Дата С')}
                    />
                </Col>
                {/* <Col {...commonProps}>
                    <DatePickerBrowser
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        inputReadOnly
                        size="large"
                        className={cls.datePicker}
                        onChange={handleChangePublishEndDate}
                        value={publishDateEnd ? dayjs(publishDateEnd) : null}
                        allowClear={false}
                        placeholder={t('Дата ПО')}
                    />
                </Col> */}
                {/* <Col {...commonProps}>
                    <DatePickerBrowser
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        inputReadOnly
                        size="large"
                        className={cls.datePicker}
                        onChange={handleChangeDeadlineStart}
                        value={deadlineStart ? dayjs(deadlineStart) : null}
                        allowClear={false}
                        placeholder={t('Срок подачи с')}
                    />
                </Col> */}
                <Col {...commonProps}>
                    <DatePickerBrowser
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        inputReadOnly
                        size="large"
                        value={deadlineEnd ? dayjs(deadlineEnd) : null}
                        className={cls.datePicker}
                        onChange={handleChangeDeadlineEnd}
                        allowClear={false}
                        placeholder={t('Срок подачи по')}
                    />
                </Col>
                <Col {...commonProps}>
                    <Input
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        value={organization}
                        onChange={handleChangeOrganization}
                        placeholder={t('Организация')}
                        size="large"
                        className={cls.summPicker}
                    />
                </Col>
                <Col {...commonProps}>
                    <InputNumber
                        value={plannedAmountStart}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        onChange={handleChangePlannedAmountStart}
                        placeholder={t('Сумма тендера от')}
                        size="large"
                        className={cls.summPicker}
                    />
                </Col>
                <Col {...commonProps}>
                    <InputNumber
                        value={plannedAmountEnd}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSearchClick()
                            }
                        }}
                        onChange={handleChangePlannedAmountEnd}
                        placeholder={t('Сумма тендера до')}
                        size="large"
                        className={cls.summPicker}
                    />
                </Col>
            </Row>
            <HStack max align="end" justify="end" className={cls.reset}>
                <MyButton variant="link" size="s" onClick={onResetClick}>
                    {t('сбросить')}
                </MyButton>
            </HStack>
        </div>
    )
})

export default TenderFilter
