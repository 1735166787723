import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema'
import { UserActions } from '../../../../entities/User'
import { USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage'
import {
    UserRegisterProps,
    UserRegisterResponse
} from '../types/UserRegisterSchema'
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError'

export const fetchUserRegister = createAsyncThunk<
    UserRegisterResponse,
    UserRegisterProps,
    ThunkConfig<string>
>('userRegister/fetchUserRegister', async (data, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi
    try {
        const response = await extra.api.post<UserRegisterResponse>(
            '/auth/register',
            data.registerData
        )

        if (!response.data) {
            throw new Error()
        }
        localStorage.setItem(USER_TOKEN_KEY, response.data.userToken)
        dispatch(UserActions.setAuth(true))
        data.callBack()
        return response.data
    } catch (e: any) {
        return rejectWithValue(handleAsyncError(e))
    }
})
