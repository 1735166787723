import { useTranslation } from 'react-i18next'
import { Theme, useTheme } from '../../../../app/providers/ThemeProvider'
import { UserActions } from '../../../../entities/User'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { LangSwitcher } from '../../../../shared/ui/LangSwitcher/LangSwitcher'
import { HStack } from '../../../../shared/ui/Stack'
import { New } from '../../../../shared/ui/ThemeSwitcher/ui/New'
import { AuthByUsernameActions } from '../../../AuthByUsername'
import { Tags } from '../../../Tags'
import cls from './UserProfile.module.scss'

interface Props {
    hideLangSwithcer?: boolean
}

export const UserProfile = ({ hideLangSwithcer }: Props) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const { theme } = useTheme()
    const isLight = theme === Theme.LIGHT
    const handleExitClick = () => {
        dispatch(UserActions.logOut())
        dispatch(AuthByUsernameActions.setPassword(''))
        dispatch(AuthByUsernameActions.setUsername(''))
    }
    return (
        <div className={cls.userProfile}>
            <div className={cls.userInfo}>
                <HStack max justify="between" align="start">
                    <div className={cls.details}>
                        <h2 className={cls.name}>{t('Имя пользователя')}</h2>
                        <p className={cls.plan}>
                            {t('Тариф')}: {t('Премиум')}
                        </p>
                        <p className={cls.date}>
                            {t('Дата регистрации')}: {t('01.01.2024')}
                        </p>
                        <p className={cls.theme}>
                            {t('Выбранная тема')}:{' '}
                            {isLight ? t('Светлая') : t('Тёмная')}
                        </p>
                    </div>
                    {/* <div className={cls.avatar}>
                        <img
                            src="https://via.placeholder.com/150"
                            alt="User Avatar"
                        />{' '}
                    </div> */}
                    <div className={cls.actions}>
                        <New />
                        {!hideLangSwithcer && <LangSwitcher variant="clear" />}
                    </div>
                </HStack>
            </div>

            <div className={cls.tags}>
                <h3>{t('Теги')}</h3>
                <Tags />
            </div>
            <div className={cls.availablePages}>
                <h3>{t('Доступные страницы')}</h3>
                <ul>
                    <li>{t('Главная')}</li>
                    <li>{t('Профиль')}</li>
                    <li>{t('Настройки')}</li>
                </ul>
            </div>

            <HStack justify="end" align="end">
                <MyButton onClick={handleExitClick} color="error" size="s">
                    {t('Выйти')}
                </MyButton>
            </HStack>
        </div>
    )
}
