import { RouteProps } from 'react-router-dom'
import { MainPage } from '../../../pages/MainPage/ui/MainPage'
import { PublicPageWrapper } from '../../../pages/PublicPageWrapper'
import { UserRegisterPage } from '../../../pages/UserRegisterPage'
import {
    AppRoutes,
    ChildAppRoutes,
    getRouteAboutPage,
    getRouteContactsPage,
    getRouteMainPage,
    getRouteUserProfilePage,
    getRouteUserRegisterPage
} from '../../lib/const/router'
import { AboutPage } from '../../../pages/AboutPage'
import { ContactsPage } from '../../../pages/ContactsPage'
import { UserProfilePage } from '../../../pages/UserProfilePage'

export type AppRoutesProps = RouteProps & {
    authOnly?: boolean
    child?: Record<string | ChildAppRoutes, AppRoutesProps>
    roles?: any[]
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.MAIN]: {
        path: '/',
        element: <PublicPageWrapper />,
        child: {
            [ChildAppRoutes.MAIN_PAGE]: {
                path: getRouteMainPage(),
                element: <MainPage />
            },
            [ChildAppRoutes.REGISTER]: {
                path: getRouteUserRegisterPage(),
                element: <UserRegisterPage />
            },
            [ChildAppRoutes.ABOUT]: {
                path: getRouteAboutPage(),
                element: <AboutPage />
            },
            [ChildAppRoutes.CONTACTS]: {
                path: getRouteContactsPage(),
                element: <ContactsPage />
            },
            [ChildAppRoutes.USER_PROFILE]: {
                path: getRouteUserProfilePage(),
                element: <UserProfilePage />
            }
        }
    }
}
