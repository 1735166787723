import { useState, useCallback } from 'react';
import { message } from 'antd';

const useApiRequest = <T, Args extends any[] = any[]>(
    requestFunction: (...args: Args) => Promise<T>,
    successMessage?: string,
    callback?: (...args: any) => any,
    awaitFunction?: (...args: any) => any,
) => {


    const [isLoading, setisLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<T | null>(null);
    const [isSuccess, setIsSuccess] = useState<true | null>(null)

    const fetchData = useCallback(async (...args: Args) => {
        setIsSuccess(null)
        setisLoading(true);
        setError(null);
        setData(null);

        try {
            const response = await requestFunction(...args);
            setisLoading(false);
            setData(response);
            if (successMessage && successMessage.length > 0) {
                message.success(successMessage)
            }
            setIsSuccess(true)

            if (callback) {
                callback(response)
            }

            if (awaitFunction) {
                awaitFunction()
            }
            return response;
        } catch (error: any) {

            setIsSuccess(null)
            setisLoading(false);
            setError(error.message || 'Произошла ошибка при выполнении запроса');
        }
    }, [requestFunction, awaitFunction, callback, successMessage]);

    return {
        isLoading,
        error,
        data,
        fetchData,
        isSuccess,
        setData,
        setError,
    };
}

export default useApiRequest;


