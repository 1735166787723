import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IsSearchingSchema } from '../types/IsSearchingSchema';

const initialState: IsSearchingSchema = {
    _isSearching: false
};

export const IsSearchingSlice = createSlice({
    name: 'IsSearching',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<boolean>) => {
            state._isSearching = action.payload
        },
    },

});

export const { actions: IsSearchingActions } = IsSearchingSlice;
export const { reducer: IsSearchingReducer } = IsSearchingSlice;