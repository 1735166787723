import Hamburger from 'hamburger-react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { getBurgerMenudata } from '../../model/selectors/getBurgerMenudata'
import { BurgerMenuActions } from '../../model/slices/BurgerMenuSlice'

const BurgerMenu = memo(() => {
    // const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { isOpen } = useSelector(getBurgerMenudata)

    const setOpen = () => {
        dispatch(BurgerMenuActions.set(!isOpen))
    }

    return <Hamburger toggled={isOpen} toggle={setOpen} size={20} />
})

export default BurgerMenu
