import { Button, Input, Tooltip } from 'antd'
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { openNotificationWithIcon } from '../../../../app/providers/NotificationProvider'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { getRouteMainPage } from '../../../../shared/lib/const/router'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { VStack } from '../../../../shared/ui/Stack'
import { Text } from '../../../../shared/ui/Text'
import { getUserRegisterdata } from '../../model/selectors/getUserRegisterdata'
import { fetchUserRegister } from '../../model/services'
import { UserRegisterActions } from '../../model/slices/UserRegisterSlice'
import cls from './UserRegister.module.scss'
import { validateEmail } from '../../../../shared/lib/helpers/validEmail'

interface UserRegisterProps {
    className?: string
}

const UserRegister = memo((props: UserRegisterProps) => {
    const { className } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { email, password, password2, error, isLoading } =
        useSelector(getUserRegisterdata)

    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordLengthError, setPasswordLengthError] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const onChangeUsername = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(UserRegisterActions.setEmail(event.target.value))
            setEmailError(!validateEmail(event.target.value))
        },
        [dispatch]
    )

    const onChangePassword = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newPassword = event.target.value
            dispatch(UserRegisterActions.setPassword(newPassword))
            setPasswordLengthError(newPassword.length < 8)
            setPasswordError(newPassword !== password2)
        },
        [dispatch, password2]
    )

    const onChangePassword2 = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newPassword2 = event.target.value
            dispatch(UserRegisterActions.setPassword2(newPassword2))
            setPasswordError(newPassword2 !== password)
        },
        [dispatch, password]
    )

    const navigateOnSuccess = useCallback(() => {
        openNotificationWithIcon(
            'success',
            t('Уведомление!'),
            t('Вы успешно зарегистрированы!')
        )
        navigate(getRouteMainPage())
    }, [navigate, t])

    const onRegisterClick = useCallback(() => {
        if (passwordError || emailError || passwordLengthError) {
            openNotificationWithIcon(
                'error',
                t('Ошибка'),
                passwordError
                    ? t('Пароли должны совпадать!')
                    : passwordLengthError
                    ? t('Пароль должен содержать минимум 8 символов!')
                    : t('Некорректный адрес электронной почты')
            )
            return
        }
        dispatch(
            fetchUserRegister({
                registerData: { email: email, password: password },
                callBack: navigateOnSuccess
            })
        )
    }, [
        t,
        email,
        password,
        dispatch,
        navigateOnSuccess,
        passwordError,
        emailError,
        passwordLengthError
    ])

    // Проверяем наличие ошибок и активируем/деактивируем кнопку регистрации
    useEffect(() => {
        setIsButtonDisabled(
            emailError ||
                passwordError ||
                passwordLengthError ||
                isLoading ||
                !email ||
                !password ||
                !password2
        )
    }, [
        emailError,
        passwordError,
        passwordLengthError,
        isLoading,
        email,
        password,
        password2
    ])

    return (
        <div className={classNames(cls.UserRegister, {}, [className])}>
            <VStack
                max
                gap="16"
                className={classNames(cls.LoginForm, {}, [className])}
            >
                {t('Форма регистрации')}
                {error && <Text text={t(error)} variant="error" />}
                <Input
                    disabled={isLoading}
                    type="text"
                    className={classNames(cls.input, {
                        [cls.error]: emailError
                    })}
                    placeholder={t('Введите email')}
                    onChange={onChangeUsername}
                    value={email}
                    onKeyDown={(e) => {
                        if (e.code === 'Enter' && !isButtonDisabled) {
                            onRegisterClick()
                        }
                    }}
                />
                <Tooltip trigger={['focus']} title={t('минимум 8 символов')}>
                    <Input.Password
                        disabled={isLoading}
                        type="text"
                        className={classNames(cls.input, {
                            [cls.error]: passwordError || passwordLengthError
                        })}
                        placeholder={t('Введите пароль')}
                        onChange={onChangePassword}
                        value={password}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter' && !isButtonDisabled) {
                                onRegisterClick()
                            }
                        }}
                    />
                </Tooltip>
                <Input.Password
                    disabled={isLoading}
                    type="text"
                    className={classNames(cls.input, {
                        [cls.error]: passwordError
                    })}
                    placeholder={t('Повторите пароль')}
                    onChange={onChangePassword2}
                    value={password2}
                    onKeyDown={(e) => {
                        if (e.code === 'Enter' && !isButtonDisabled) {
                            onRegisterClick()
                        }
                    }}
                />

                <VStack align="end">
                    <Button
                        className={cls.loginBtn}
                        onClick={onRegisterClick}
                        disabled={isButtonDisabled}
                    >
                        {t('Зарегистрироваться')}
                    </Button>
                </VStack>
                {isLoading && <Loader />}
            </VStack>
        </div>
    )
})

export default UserRegister
