import { Row, Col } from 'antd'
import React from 'react'

interface Props {
    children: React.ReactElement | React.ReactNode
}
export const gridCommonProps = {
    xs: 23,
    sm: 23,
    md: 23,
    lg: 23,
    xl: 18
}

export const MainGridComponent = (props: Props) => {
    const { children } = props
    return (
        <Row justify={'center'}>
            <Col {...gridCommonProps}>{children}</Col>
        </Row>
    )
}
