import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema'
import { FetchTenderResponse } from '../types/TendersSchema'

interface FetchTendersParams {
    perPage: number
    pageNumber: number
}

export const fetchTenders = createAsyncThunk<
    FetchTenderResponse,
    FetchTendersParams,
    ThunkConfig<string>
>('tenders/fetchTenders', async (data, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi
    try {
        const response = await extra.api.get<FetchTenderResponse>(
            '/tenders-api/get_last_tenders',
            {
                params: {
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('error')
    }
})
export const fetchTendersInfinite = createAsyncThunk<
    FetchTenderResponse,
    FetchTendersParams,
    ThunkConfig<string>
>('tenders/fetchTendersInfinite', async (data, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi
    try {
        const response = await extra.api.get<FetchTenderResponse>(
            '/tenders-api/get_last_tenders',
            {
                params: {
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('Ошибка при получении данных!')
    }
})
