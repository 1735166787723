import { ConfigProvider } from 'antd'
import ru_RU from 'antd/locale/ru_RU'
import { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUserInited } from '../entities/User'
import { fetchRefreshToken } from '../entities/User/model/services'
import { classNames } from '../shared/lib/classNames/classNames'
import { useAppDispatch } from '../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { PageLoader } from '../shared/ui/PageLoader/PageLoader'
import { Navbar } from '../widgets/Navbar/Navbar'
import './App.css'
import { AppRouter } from './providers/router'
import { Theme, useTheme } from './providers/ThemeProvider'

export const App = () => {
    const { theme } = useTheme()
    const dispatch = useAppDispatch()
    const inited = useSelector(getUserInited)

    useEffect(() => {
        if (!inited) {
            dispatch(fetchRefreshToken())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, inited])

    if (!inited) {
        return (
            <Suspense>
                <div className="app">
                    <PageLoader />
                </div>
            </Suspense>
        )
    }

    return (
        <div className={classNames('app', {}, [theme || Theme.LIGHT])}>
            <Navbar />
            <ConfigProvider
                locale={ru_RU}
                theme={{
                    token: {
                        fontFamily: 'Giltroy'
                    },
                    components: {
                        Table: {
                            headerBorderRadius: 20,
                            headerBg: '#f4f7fa'
                        }
                    }
                }}
            >
                <Suspense>
                    <AppRouter />
                </Suspense>
            </ConfigProvider>
        </div>
    )
}
