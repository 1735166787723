import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { searchTenders, searchTendersInfinite } from '../services'
import {
    FoundTenderItem,
    FoundTendersSchema
} from '../types/FoundTendersSchema'

const initialState: FoundTendersSchema = {
    foundtenders: [] as FoundTenderItem[],
    overall_tenders_count_found: 0
}

export const FoundTendersSlice = createSlice({
    name: 'FoundTenders',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchTenders.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(searchTenders.fulfilled, (state, action) => {
                state.isLoading = false
                state.foundtenders = action.payload.tenders
                state.overall_tenders_count_found =
                    action.payload.overall_tenders_count
            })
            .addCase(searchTenders.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(searchTendersInfinite.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(searchTendersInfinite.fulfilled, (state, action) => {
                state.isLoading = false
                let newData = state.foundtenders ? [...state.foundtenders] : []
                state.foundtenders = [...newData, ...action.payload.tenders]
                state.overall_tenders_count_found =
                    action.payload.overall_tenders_count
            })
            .addCase(searchTendersInfinite.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: FoundTendersActions } = FoundTendersSlice
export const { reducer: FoundTendersReducer } = FoundTendersSlice
