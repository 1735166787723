import { postRequestError } from '../const/text'

export function handleAsyncError(error: any): string {
    // Проверяем, если ошибка уже содержит нужное сообщение
    if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message
    }

    // Если это ошибка типа Error
    if (error instanceof Error) {
        return error.message
    }

    // Любые другие непредвиденные ошибки
    return postRequestError
}
