import { classNames } from '../../lib/classNames/classNames'
import cls from './MainPageAlert.module.scss'

interface Props {
    title: string
    text: string
}
export const MainPageAlert = ({ text, title }: Props) => {
    return (
        <div className={classNames(cls.MainPageAlert, {}, [])}>
            <div>
                <div className={cls.title}>{title}</div>
                <div className={cls.text}> {text}</div>
            </div>
        </div>
    )
}
