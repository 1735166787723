// import Lottie from 'lottie-react'
import './Loader.scss'

interface LoaderProps {
    className?: string
}

export const Loader = ({ className }: LoaderProps) => (
    <div className="loader"></div>
)
// export const Loader = ({ className }: LoaderProps) => (
//     <Lottie animationData={animation} loop style={{ width: '200px' }} />
// )
